import request from '@/utils/Request';

// 活动列表
export function getVoteList(params) {
  return request({
    url: `/Videovote/admin/showVoteList`,
    method: 'get',
    params,
  });
}

// 视频活动修改页
export function getVoteInfo(params) {
  return request({
    url: `/Videovote/admin/VoteInfo`,
    method: 'get',
    params,
  });
}

// 添加/修改活动信息
export function saveVoteBasicInfo(data) {
  return request({
    url: `/Videovote/admin/updVoteInfo`,
    method: 'post',
    data,
  });
}

// 添加/修改视频信息
export function saveVoteVideoInfo(data) {
  return request({
    url: `/Videovote/admin/updVideo`,
    method: 'post',
    data,
  });
}

// 添加/修改视频信息
export function deleteVoteVideo(data) {
  return request({
    url: `/Videovote/admin/delVideo`,
    method: 'delete',
    data,
  });
}

// 修改活动状态
export function updateVoteStatus(data) {
  return request({
    url: `/Videovote/admin/updStatus`,
    method: 'post',
    data,
  });
}
