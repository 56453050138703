<template>
  <div>
    <div class="flex flex-end pt-10 mb-20">
      <a-button :disabled="fetching" @click="back">返回列表</a-button>
      <a-button @click="onCreate">添加视频</a-button>
    </div>

    <a-empty v-if="!fetching && (!list || !list.length)"/>
    <div v-if="fetching && (!list || !list.length)" class="ant-empty">加载中...</div>
    <a-form-model v-for="(item, formIndex) in list"
                  :key="item.id"
                  ref="formRef"
                  label-align="right"
                  layout="inline"
                  :model="item"
                  :rules="rules"
                  :label-col="{span: 8}"
                  :wrapper-col="{span: 12}"
                  class="form-model">
      <a-form-model-item label="图片" prop="pic_resource_id">
        <a-upload :disabled="uploading[formIndex][0] || loading[formIndex]"
                  list-type="picture-card"
                  accept="image/*"
                  action="/public/conf/upload"
                  :headers="{'X-Auth-Token': $store.state.User.token || ''}"
                  name="file"
                  :data="{'source': 'video_vote'}"
                  :multiple="false"
                  :show-upload-list="false"
                  :before-upload="imgBeforeUpload"
                  @change="onImgUploadChange($event, formIndex)">
          <div class="uploader-inner">
            <BlockLoading v-if="uploading[formIndex][0]" tip="正在上传"/>
            <div v-else-if="item.pic_resource_url">
              <img :src="item.pic_resource_url" alt="" class="uploaded-pic">
            </div>
            <div v-else style="width: 180px;">
              <a-icon type="plus"/>
              <div>请上传图片</div>
            </div>
          </div>
        </a-upload>
      </a-form-model-item>

      <a-form-model-item label="视频" prop="video_resource_id">
        <a-upload :disabled="uploading[formIndex][1] || loading[formIndex]"
                  list-type="picture-card"
                  accept="video/*"
                  action="/public/conf/upload"
                  :headers="{'X-Auth-Token': $store.state.User.token || ''}"
                  name="file"
                  :data="{'source': 'video_vote'}"
                  :multiple="false"
                  :show-upload-list="false"
                  :before-upload="videoBeforeUpload"
                  @change="onVideoUploadChange($event, formIndex)">
          <div class="uploader-inner">
            <BlockLoading v-if="uploading[formIndex][1]" tip="正在上传"/>
            <div v-else-if="item.video_resource_url">
              <video :src="item.video_resource_url" class="uploaded-pic"/>
            </div>
            <div v-else style="width: 180px;">
              <a-icon type="plus"/>
              <div>请上传视频</div>
            </div>
          </div>
        </a-upload>
      </a-form-model-item>

      <a-form-model-item label="作者" prop="author">
        <a-input v-model="item.author"
                 :allow-clear="true"
                 :disabled="loading[formIndex]"
                 placeholder="请填写作者姓名"
                 style="min-width: 180px;"
                 @change="updateUnsaved(formIndex)"/>
      </a-form-model-item>
      <a-form-model-item class="ml-20">
        <a-button type="primary"
                  :loading="uploading[formIndex][0] || uploading[formIndex][1] || loading[formIndex]"
                  @click="saveForm(formIndex)">
          <!--<span v-if="!item.saved">待</span>-->
          <span>保存</span>
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button :loading="uploading[formIndex][0] || uploading[formIndex][1] || loading[formIndex]"
                  @click="deleteForm(formIndex)">
          <span>删除</span>
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  getVoteInfo,
  saveVoteVideoInfo,
  deleteVoteVideo,
} from '../api';

import BlockLoading from '@/components/BlockLoading.vue';

const defaultForm = {
  id: 0,

  pic_resource_id: '',
  pic_resource_url: '',

  video_resource_id: '',
  video_resource_url: '',
  seed: '',

  author: '',

  saved: true, // 是否已保存
};

export default {
  name: 'Content',
  components: {
    BlockLoading,
  },
  data() {
    return {
      fetching: false,
      loading: [],
      uploading: [],
      globalId: -1,

      list: [],
      rules: {
        author: [{ required: true, trigger: 'blur', message: '请填写视频作者姓名' }],
        pic_resource_id: [{ required: true, trigger: 'change', message: '请上传图片' }],
        video_resource_id: [{ required: true, trigger: 'change', message: '请上传视频' }],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    back() {
      this.$router.back();
    },
    onCreate() {
      this.globalId -= 1;

      this.list.push({
        ...defaultForm,
        id: this.globalId,
      });
      this.loading.push(false);
      this.uploading.push([false, false]);
    },

    async getData() {
      if (!this.$route.query?.id || this.fetching) {
        return;
      }
      this.fetching = true;

      const data = await getVoteInfo({
        id: this.$route.query.id,
      }).finally(() => {
        this.fetching = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }
      this.list = (data?.data?.video_list || data?.data?.vedio_list || []).map((i) => ({
        ...i,
        saved: true,
        video_resource_url: i.video_resource_url || i.vedio_resource_url || '',
      }));
      this.loading = this.list.map(() => false);
      this.uploading = this.list.map(() => [false, false]);
    },

    imgBeforeUpload() {
    },

    onImgUploadChange(evt, index) {
      this.uploading[index][0] = evt.file.status === 'uploading';
      this.uploading = [...this.uploading];

      if (evt.file.status === 'done') {
        const res = evt.file.response || undefined;
        if (!res || res.error_code) {
          this.$message.error(res?.message || '图片上传失败');
        } else {
          this.$message.success(res.message || '图片上传成功');
          this.list[index].pic_resource_id = res.data?.file_resource_id || '';
          this.list[index].pic_resource_url = res.data?.url || '';
          this.updateUnsaved(index);
        }
      }
    },

    videoBeforeUpload() {
    },

    onVideoUploadChange(evt, index) {
      this.uploading[index][1] = evt.file.status === 'uploading';
      this.uploading = [...this.uploading];

      if (evt.file.status === 'done') {
        const res = evt.file.response || undefined;
        if (!res || res.error_code) {
          this.$message.error(res?.message || '视频上传失败');
        } else {
          this.$message.success(res.message || '视频上传成功');
          this.list[index].video_resource_id = res.data?.file_resource_id || '';
          this.list[index].video_resource_url = res.data?.url || '';
          this.list[index].seed = res.data?.seed || '';
          this.updateUnsaved(index);
        }
      }
    },

    updateUnsaved(index) {
      this.list[index].saved = false;
    },

    saveForm(index) {
      if (this.$refs.formRef[index]) {
        this.$refs.formRef[index].validate((validated) => {
          if (validated) {
            this.confirmSaveForm(index);
          }
        });
      }
    },
    async confirmSaveForm(index) {
      if (this.loading[index]) {
        return;
      }
      this.loading[index] = true;
      this.loading = [...this.loading];

      const form = this.list[index] || {};
      const params = {
        id: this.$route.query?.id || '',
        author: form.author,
        pic_resource_id: form.pic_resource_id,
        seed: form.seed,
        vedio_url: form.video_resource_url,
      };
      if (form.id > 0) {
        params.video_id = form.id || '';
      }

      const data = await saveVoteVideoInfo(params).finally(() => {
        this.loading[index] = false;
        this.loading = [...this.loading];
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');
      this.list[index].id = data?.data || this.list[index].id;
    },

    deleteForm(index) {
      this.$confirm({
        content: `确认删除该视频？`,
        onOk: () => this.confirmDeleteForm(index),
      });
    },
    confirmDeleteForm(index) {
      const id = this.list[index]?.id;
      this.loading.splice(index, 1);
      this.uploading.splice(index, 1);
      if (id > 0) {
        this.deleteVoteVideo(index);
      } else {
        this.list.splice(index, 1);
      }
    },
    async deleteVoteVideo(index) {
      const id = this.list[index]?.id;
      this.loading = true;
      const data = await deleteVoteVideo({
        id: this.$route.query.id,
        video_id: id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data.message || '删除成功');
      this.list.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.form-model {
  margin-bottom: 20px;
  padding-top: 10px;
  border-bottom: 1px solid #DFDFDF;
}

.uploader-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploaded-pic {
  max-width: 150px;
  max-height: 200px;
}
</style>
