<template>
  <router-view class="ph-20"/>
</template>

<script>
export default {
  name: 'Index',
};
</script>

<style scoped>

</style>
