<template>
  <a-form-model label-align="right"
                ref="formRef"
                :model="form"
                :rules="rules"
                :label-col="{span: 4}"
                :wrapper-col="{span: 12}">
    <a-form-model-item label="活动名称" prop="title">
      <a-input v-model="form.title" :disabled="loading" placeholder="活动名称"/>
    </a-form-model-item>
    <a-form-model-item label="投票类型" prop="is_day">
      <a-radio-group v-model="form.is_day">
        <a-radio :value="1">每天次数</a-radio>
        <a-radio :value="0">总次数</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="投票次数" prop="vote_num">
      <a-input-number v-model="form.vote_num"
                      :min="0"
                      :formatter="value => `${value}`.replace(/\.*/g, '')"
                      :parser="value => `${value}`.replace(/\.*/g, '')"
                      :disabled="loading"
                      placeholder="投票次数"
                      style="width: 160px;"/>
    </a-form-model-item>

    <div class="flex flex-start time-wrapper">
      <a-form-model-item label="活动时间"
                         prop="start_time"
                         :label-col="{span: 8}"
                         :wrapper-col="{span: 12}"
                         class="form-item form-item-start-time">
        <a-date-picker v-model="form.start_time"
                       :show-time="{ format: 'HH:mm' }"
                       format="YYYY-MM-DD HH:mm"/>
        <span class="ml-10 mr-10">至</span>
      </a-form-model-item>
      <a-form-model-item label=""
                         prop="end_time"
                         :wrapper-col="{span: 24}"
                         class="form-item form-item-end-time">
        <a-date-picker v-model="form.end_time"
                       :show-time="{ format: 'HH:mm' }"
                       format="YYYY-MM-DD HH:mm"/>
      </a-form-model-item>
    </div>

    <a-form-model-item label="规则说明" prop="instructions">
      <a-textarea v-model="form.instructions"
                  :disabled="loading"
                  :auto-size="{ minRows: 4, maxRows: 10 }"
                  placeholder="规则说明"/>
    </a-form-model-item>
    <a-form-model-item label="页面背景" prop="background_resource_id">
      <a-upload :disabled="uploading || loading"
                list-type="picture-card"
                accept="image/*"
                action="/public/conf/upload"
                :headers="{'X-Auth-Token': $store.state.User.token || ''}"
                name="file"
                :data="{'source': 'video_vote'}"
                :multiple="false"
                :show-upload-list="false"
                :before-upload="beforeUpload"
                @change="onUploadChange">
        <div v-if="form.background_img">
          <img :src="form.background_img" alt="" class="uploaded-pic">
        </div>
        <div v-else style="width: 180px;">
          <a-icon type="plus"/>
          <div>请上传背景图片</div>
        </div>
      </a-upload>
    </a-form-model-item>
    <a-form-model-item class="mt-20" :wrapper-col="{offset: 4}">
      <a-button type="primary" :disabled="loading || uploading" @click="onSubmit">保存</a-button>
      <a-button :disabled="loading || uploading" @click="back">返回列表</a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import moment from 'moment';

import {
  getVoteInfo,
  saveVoteBasicInfo,
} from '../api';

export default {
  name: 'Basic',
  data() {
    return {
      loading: false,
      uploading: false,
      id: '',

      form: {
        title: '',
        is_day: 1,
        vote_num: 0,
        instructions: '',
        start_time: undefined,
        end_time: undefined,
        background_resource_id: '',
        background_img: '',
      },
      rules: {
        title: [{ required: true, trigger: 'blur', message: '请填写活动名称' }],
        is_day: [{ required: true, trigger: 'change', message: '请选择投票类型' }],
        vote_num: [{ required: true, trigger: 'blur', message: '请输入投票次数' }],
        start_time: [{ required: true, trigger: 'change', message: '请选择活动开始时间' }],
        end_time: [{ required: true, trigger: 'change', message: '请选择活动结束时间' }],
        background_resource_id: [{ required: true, trigger: 'change', message: '请上传背景图' }],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    beforeUpload() {
    },
    back() {
      this.$router.back();
    },

    async getData() {
      if (!this.$route.query?.id || this.loading) {
        return;
      }
      this.loading = true;

      const data = await getVoteInfo({
        id: this.$route.query.id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }
      const result = data?.data || {};
      this.form = {
        title: result.title,
        is_day: +result.is_day || 0,
        vote_num: +result.vote_num || 0,
        instructions: result.instructions || '',
        start_time: (result.start_time || result.start_day) ? moment(
          result.start_time || result.start_day || '',
          'YYYY-MM-DD HH:mm',
        ) : undefined,
        end_time: (result.end_time || result.end_day) ? moment(
          result.end_time || result.end_day || '',
          'YYYY-MM-DD HH:mm',
        ) : undefined,
        background_resource_id: result.background_resource_id || '',
        background_img: result.background_resource_url || '',
      };
    },

    onUploadChange(evt) {
      this.uploading = evt.file.status === 'uploading';

      if (evt.file.status === 'done') {
        const res = evt.file.response || undefined;
        if (!res || res.error_code) {
          this.$message.error(res?.message || '图片上传失败');
        } else {
          this.$message.success(res.message || '图片上传成功');
          this.form.background_resource_id = res.data?.file_resource_id || '';
          this.form.background_img = res.data?.url || '';
        }
      }
    },

    onSubmit() {
      this.$refs.formRef.validate((validated) => {
        if (validated) {
          this.save();
        }
      });
    },

    async save() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        ...this.form,
        start_time: this.form.start_time?.format('YYYY-MM-DD HH:mm') || '',
        end_time: this.form.end_time?.format('YYYY-MM-DD HH:mm') || '',
      };
      if (this.$route.query?.id) {
        params.id = this.$route.query?.id || '';
      }
      delete params.background_img;

      const data = await saveVoteBasicInfo(params).finally(() => {
        this.loading = false;
      });
      if (!data || data?.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      this.$router.replace({
        path: this.$route.path,
        query: {
          id: data?.data?.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.time-wrapper {
  position: relative;
  width: 100%;

  .form-item-start-time {
    width: calc(1 / 6 * 100% + 230px);

    ::v-deep .ant-form-item-label {
      width: calc(100% - 230px);
    }

    ::v-deep .ant-form-item-control-wrapper {
      width: 230px;
    }
  }

  .form-item-end-time {
  }
}

.uploaded-pic {
  max-width: 200px;
  max-height: 200px;
}
</style>
